:root {
  --primary-color: #fff;
  --primary-color2: #1f1f2c;
  --primary-color3: #135893;
  --primary-color4: #597a39;
  --primary-color5: #f8f8f8;
  --primary-color6: #14141f;
  --primary-color7: #f8f8f8;
  --bg-section2: #f8f8f8;
  --primary-color8: #597a39;
  --primary-color9: #797271;

  --default-light-banner-url: url(../images/default-banner.webp);
  --profile-light-banner-url: url(../images/profile-banner.webp);
}

/* thai */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraBGwCYdA.woff2)
    format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraaGwCYdA.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcrabGwCYdA.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraVGwA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.heading {
  margin-top: 60px !important;
}

body,
button,
input,
select,
textarea {
  font-family: 'Kanit' !important;
}

.flat-title-page.inner {
  padding: 200px 0;
}

#footer {
  background-color: #221f20 !important;
}

#footer h5,
#footer .mg-t-20 a,
.widget-menu ul li a {
  color: #fff !important;
}

.widget-social.style-1 ul li a,
.menu_card .dropdown-menu a {
  color: #000 !important;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus {
  background-color: #0d5093 !important;
  border-color: #0d5093 !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus {
  border: 2px solid #fff !important;
}

/* Searchbox */
@media (max-width: 992px) {
  input[type='search'] {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']:focus {
    border: thin solid var(--primary-color9) !important;
  }

  input[type='search']::placeholder {
    color: var(--primary-color9) !important;
  }

  .header-search-form-container button {
    color: var(--primary-color9) !important;
  }
}

input[type='search']::placeholder {
  color: #fff !important;
}

/* Banner */

.mainslider .swiper-button-prev,
.mainslider .swiper-button-next {
  background: var(--primary-color4) !important;
}

.header_1.header_2.style2 {
  background: #3c5569 !important;
}

.flat-cart-item .overlay {
  background-size: cover !important;
  background-image: url('../images/index-banner.webp') !important;
}

.wrap-cart .cart_item.style2 p {
  text-shadow: 2px 2px var(--primary-color4) !important;
}
